.searchResultParent .searchResultChild{
  visibility: hidden;
}

.searchResultParent:hover .searchResultChild{
  visibility: visible;
}

.visibleOverride{
  visibility: visible !important;
}
