.highlightClass{
  border: 1px solid #1876d2;
  border-radius: 12px;
}

.tooltipClassDark{
  background-color: #383838 !important;
  text-shadow: none !important;
  border-radius: 12px !important;
  color: #ffffff !important
}
.tooltipClassLight{
  border-radius: 12px !important;
  text-shadow: none !important;
}

