
.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  /* width: 24px; */
  transition: transform linear .1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
}

.dropdownIconParent .dropdownIconChild{
  visibility: hidden;
}

.dropdownIconParent:hover .dropdownIconChild{
  visibility: visible;
}

.visibleOverride{
  visibility: visible !important;
}
