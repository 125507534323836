.treeRoot {
  height: 100%;
  padding-bottom: 20px !important; 
  /* padding bottom necessary so there is always room to drag a nested item out of a folder */
}

.draggingSource {
  opacity: .75;
  background-image: linear-gradient(rgb(0 0 0/30%) 0 0)
}

.dropTarget {
  background-image: linear-gradient(rgb(0 0 0/30%) 0 0)
  /* background-color: #930000 !important; */
}

.lightContainer {
  background-color: #ffffff;
}

.darkContainer{
  background-color: #1e1e1e;
  }
